import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { WindowProps } from "./windowsReducer";

interface Wallet {
  adaAmount: number;
}
const initialState: Wallet = {
  adaAmount: 0,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState: initialState,
  reducers: {
    updateWallet(state: Wallet, action: PayloadAction<Wallet>) {
      state.adaAmount = action.payload.adaAmount;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateWallet } = walletSlice.actions;

export const getWallet = (state: RootState) => state.wallet;

export default walletSlice.reducer;
