import { RootState } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import logo from "../../images/stable-logo.png";
import { useDispatch } from "react-redux";

export interface WindowProps {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
  width: number;

  centered?: boolean;
  titleText: string;
  content: JSX.Element;
  id: number;
  visible: boolean;
  closable: boolean;
  active: boolean;
  maximized: boolean;
  maximizable?: boolean;
}

export let currentWindowId = 0;

export const getNextWindowId = () => {
  currentWindowId++;

  return currentWindowId;
};

export const getCurrentWindowId = () => {
  return currentWindowId;
};

export let currentWindowIndex = 1;
export const getNextWindowIndex = () => {
  currentWindowIndex++;

  return currentWindowIndex;
};

// const StatusWindow = (props: { windowId: number }) => {
//   const loadingBarRef = useRef<HTMLDivElement>(null);
//   const dispatch = useDispatch();
//   const wallet = useSelector(getWallet);
//   const [width, setWidth] = useState(0);
//   const [height, setHeight] = useState(0);

//   const startDate = new Date(2023, 0o4, 0o13, 16, 0, 0, 0);
//   const [currentTime, setCurrentTime] = useState(new Date());
//   const [startedUpdatingDate, setStartedUpdatingDate] = useState(false);

//   useEffect(() => {
//     if (!startedUpdatingDate) {
//       setStartedUpdatingDate(true);

//       const updateDate = async () => {
//         while (!startedUpdatingDate) {
//           setCurrentTime(new Date());
//           await sleep(10000);
//         }
//       };
//       updateDate();
//     }
//   }, []);
//   const differenceInMinutes =
//     (currentTime.getTime() - startDate.getTime()) / 1000 / 60;

//   useLayoutEffect(() => {
//     if (loadingBarRef.current) {
//       setWidth(loadingBarRef.current.clientWidth);
//       setHeight(loadingBarRef.current.clientHeight);
//     }
//   }, [loadingBarRef]);

//   const [windowSize, setWindowSize] = useState({
//     width: window.innerWidth,
//     height: window.innerHeight,
//   });

//   useEffect(() => {
//     function handleResize() {
//       setWindowSize({
//         width: window.innerWidth,
//         height: window.innerHeight,
//       });
//     }

//     window.addEventListener("resize", handleResize);

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const windows = useSelector(getWindows);

//   const cardanoRef = useRef(null);
//   const [percentage, setPercentage] = useState(
//     Math.floor((wallet.adaAmount / 220000) * 100)
//   );
//   // console.log("PERCENT", percentage);

//   const renderLoadingParts = () => {
//     const amount = Math.floor(Math.floor(width / 10) * (percentage / 100));

//     return Array.from(new Array(amount), (x, i) => {
//       return (
//         <>
//           <div
//             style={{
//               width: "8px",
//               margin: "0 1px",
//               background: "#000080",
//               height: "100%",
//             }}
//           ></div>
//         </>
//       );
//     });
//   };

//   return (
//     <>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           position: "relative",
//           gap: "4px",
//         }}
//       >
//         <div
//           style={{
//             width: "70%",
//             display: "flex",
//             justifyContent: "space-between",
//             margin: "12px 0",
//             position: "relative",
//           }}
//         >
//           <img
//             ref={cardanoRef}
//             src={cardano}
//             className={"cardanoMovement"}
//             alt="test"
//             style={{
//               width: "24px",
//               height: "24px",
//               position: "absolute",
//               left: "32px",
//               transition: "5s",
//             }}
//           />
//           <img
//             src={globe}
//             alt="test"
//             style={{ width: "32px", height: "32px" }}
//           />
//           <img
//             src={recycle}
//             alt="test"
//             style={{ width: "32px", height: "32px" }}
//           />
//         </div>
//         <div>In progress: {percentage}%</div>
//         <div>Pre-sale</div>
//         <div
//           ref={loadingBarRef}
//           style={{
//             height: "12px",
//             border: "1px solid",
//             borderTopColor: "#808080",
//             borderRightColor: "#FFFFFF",
//             borderBottomColor: "#FFFFFF",
//             borderLeftColor: "#808080",
//             position: "relative",
//             padding: "2px",
//             display: "flex",
//           }}
//         >
//           {renderLoadingParts()}
//         </div>

//         <div style={{ display: "flex" }}>
//           <div style={{ minWidth: "100px" }}>Estimated time left:</div>
//           <div>
//             {Math.floor(
//               (220000 - wallet.adaAmount) /
//                 (wallet.adaAmount / differenceInMinutes)
//             ) >
//             1440 - differenceInMinutes
//               ? Math.floor(1440 - differenceInMinutes)
//               : Math.floor(
//                   (220000 - wallet.adaAmount) /
//                     (wallet.adaAmount / differenceInMinutes)
//                 ).toLocaleString("en-US", {
//                   minimumFractionDigits: 0,
//                 })}{" "}
//             min (
//             {Math.floor(wallet.adaAmount * 422224).toLocaleString("en-US", {
//               minimumFractionDigits: 0,
//             })}{" "}
//             $STABLE of 92,889,311,102 $STABLE)
//           </div>
//         </div>
//         <div style={{ display: "flex" }}>
//           <div style={{ minWidth: "100px" }}>Selling rate:</div>
//           <div>
//             {Math.floor(
//               (wallet.adaAmount / differenceInMinutes) * 422224
//             ).toLocaleString("en-US", {
//               minimumFractionDigits: 0,
//             })}{" "}
//             $STABLE/minute
//           </div>
//         </div>

//         <div
//           style={{
//             display: "flex",
//             justifyContent: "end",
//             gap: "8px",
//             marginTop: "16px",
//           }}
//         >
//           <button disabled>Delete coin</button>
//           <button
//             onClick={() => {
//               let found = false;
//               windows.forEach((window) => {
//                 if (window.titleText === "Buy $STABLE") {
//                   found = true;
//                   dispatch(setNewActiveWindow(window.id));
//                 }
//               });
//               if (!found) {
//                 const xRandom = Math.floor(
//                   Math.random() * (windowSize.width - 300)
//                 );
//                 const yRandom = Math.floor(
//                   Math.random() * (windowSize.height - 34 - 426)
//                 );
//                 dispatch(
//                   addWindow({
//                     width: 250,
//                     left: windowSize.width < 768 ? 0 : xRandom,
//                     top: windowSize.width < 768 ? 0 : yRandom,
//                     titleText: "Buy $STABLE",
//                     id: getNextWindowId(),
//                     content: <BuyWindow windowId={getCurrentWindowId()} />,
//                     visible: true,
//                     closable: true,
//                     active: true,
//                   })
//                 );
//               }
//             }}
//           >
//             Buy
//           </button>
//           <button onClick={() => dispatch(removeWindow(props.windowId))}>
//             Cancel
//           </button>
//         </div>
//       </div>
//     </>
//   );
// };

// const BuyWindow = (props: { windowId: number }) => {
//   const [sliderValue, setSliderValue] = useState(50);
//   const [address, setAddress] = useState("");
//   const dispatch = useDispatch();
//   const [windowSize, setWindowSize] = useState({
//     width: window.innerWidth,
//     height: window.innerHeight,
//   });
//   useEffect(() => {
//     function handleResize() {
//       setWindowSize({
//         width: window.innerWidth,
//         height: window.innerHeight,
//       });
//     }

//     window.addEventListener("resize", handleResize);

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);
//   return (
//     <>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           position: "relative",

//           padding: "12px 4px",
//         }}
//       >
//         <div style={{ fontSize: "12px" }}>
//           You are about to buy an absolute worthless meme coin. Make sure to
//           only use funds you can’t afford to loose to make the experience much
//           more exciting for both sides.
//         </div>
//         <fieldset
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             gap: "8px",
//             margin: "16px 0",
//             // border: "1px solid #707070",
//             padding: "16px 4px 8px 4px",
//             position: "relative",
//           }}
//         >
//           <div
//             style={{
//               position: "absolute",
//               background: "#c0c0c0",
//               top: "-8px",
//               padding: "0 2px",
//             }}
//           >
//             Input amount
//           </div>
//           <div id={"asdads"} className="field-row amountSelect">
//             <label htmlFor="range26" style={{ minWidth: "fit-content" }}>
//               50 ₳
//             </label>

//             <input
//               id="range26"
//               type="range"
//               min="50"
//               max="2000"
//               value={sliderValue}
//               onChange={(e: any) => setSliderValue(e.target.value)}
//             />

//             <label htmlFor="range27" style={{ minWidth: "fit-content" }}>
//               2000 ₳
//             </label>
//           </div>
//           <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
//             <div style={{ display: "flex", gap: "4px" }}>
//               <div>ADA</div>
//               <input
//                 type={"number"}
//                 value={sliderValue}
//                 style={{ width: "100%" }}
//                 onChange={(e) => {
//                   // setSliderValue(
//                   //   Math.max(50, Math.min(50000000, Number(+e.target.value)))
//                   // );
//                   if (e.target.value.length <= 20) {
//                     setSliderValue(+e.target.value);
//                   }
//                 }}
//                 placeholder="1723"
//               />
//             </div>
//             <div style={{ display: "flex", gap: "4px" }}>
//               <div style={{ color: "transparent" }}>ADA</div>
//               <div style={{ width: "100%" }}>Example: 2000</div>
//             </div>
//           </div>
//           {/* <div style={{ marginTop: "16px" }}>
//             $STABLE:{" "}
//             {(sliderValue * 422224).toLocaleString("en-US", {
//               minimumFractionDigits: 0,
//             })}
//           </div> */}
//         </fieldset>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "end",
//             marginTop: "16px",
//             gap: "8px",
//           }}
//         >
//           <button
//             onClick={() => {
//               return;
//               if (sliderValue >= 50) {
//                 window.open(
//                   "https://pay.nmkr.io/?receiverAddress=addr1qx06ec8uxu2jsgqzwvylyyf6zwlq8ufelluk4kaqy88h9yt4rcqm0adc089u252ddvyw5fq2t2wfxf92u6afzy69n2msylcsh8" +
//                     "&amount=" +
//                     sliderValue * 1000000 +
//                     "&note=$STABLE%20Pre-sale",
//                   "_blank"
//                 );
//               } else {
//                 const xRandom = Math.floor(
//                   Math.random() * (windowSize.width - 300)
//                 );
//                 const yRandom = Math.floor(
//                   Math.random() * (windowSize.height - 34 - 426)
//                 );
//                 dispatch(
//                   addWindow({
//                     left: xRandom,
//                     top: yRandom,
//                     width: 375,
//                     titleText: "Critical Error",
//                     content: (
//                       <div
//                         style={{
//                           display: "flex",
//                           gap: "8px",
//                           padding: "8px",
//                           justifyContent: "space-between",
//                         }}
//                       >
//                         <img
//                           style={{ width: "32px", height: "32px" }}
//                           src={error}
//                         />
//                         <div style={{ width: "180px" }}>
//                           The user has performed an illegal operation! The
//                           website will shut down.
//                           <br /> <br /> If the problem persists, contact the
//                           website owner.
//                         </div>
//                         <div
//                           style={{
//                             display: "flex",
//                             flexDirection: "column",
//                             alignItems: "inbetween",
//                             gap: "8px",
//                             marginLeft: "32px",
//                           }}
//                         >
//                           <button>Close</button>
//                           <div style={{ display: "flex", flexGrow: 1 }}></div>
//                           <button>Details</button>
//                         </div>
//                       </div>
//                     ),
//                     id: getNextWindowId(),
//                     visible: true,
//                     closable: true,
//                     active: true,
//                   })
//                 );
//               }
//             }}
//           >
//             Buy
//           </button>
//           {/* <button onClick={() => dispatch(removeWindow(props.windowId))}>
//             Cancel
//           </button> */}
//         </div>
//       </div>
//     </>
//   );
// };

const StableWindowContent = () => {
  const dispatch = useDispatch();
  // const windows = useSelector(getWindows);
  // const wallet = useSelector(getWallet);
  // const [statusWindowId, setStatusWindowId] = useState(-1);
  // const [buyWindowId, setBuyWindowId] = useState(-1);

  // const percent = 90;

  // const [windowSize, setWindowSize] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });
  // useEffect(() => {
  //   function handleResize() {
  //     setWindowSize({
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     });
  //   }

  //   window.addEventListener("resize", handleResize);

  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          padding: "8px 16px",
        }}
      >
        <img src={logo} alt="test" style={{ width: "128px" }} />
        <div>The Memecoin Cardano deserves</div>

        <div style={{ display: "flex", gap: ".5rem" }}>
          {/* <button
            onClick={() => {
              const allWindowIds = windows.map((window) => window.id);
              if (buyWindowId == -1 || !allWindowIds.includes(buyWindowId)) {
                const xRandom = Math.floor(
                  Math.random() * (windowSize.width - 300)
                );
                const yRandom = Math.floor(
                  Math.random() * (windowSize.height - 34 - 426)
                );
                dispatch(
                  addWindow({
                    width: 250,
                    left: windowSize.width < 768 ? 0 : xRandom,
                    top: windowSize.width < 768 ? 0 : yRandom,
                    titleText: "Buy $STABLE",
                    id: getNextWindowId(),
                    content: <BuyWindow windowId={getCurrentWindowId()} />,
                    visible: true,
                    closable: true,
                    active: true,
                  })
                );
                setBuyWindowId(getCurrentWindowId());
              } else {
                dispatch(toggleVisibility(buyWindowId));
              }
            }}
          >
            Buy
          </button> */}
          {/* <button
            onClick={() => {
              const allWindowIds = windows.map((window) => window.id);
              if (
                statusWindowId == -1 ||
                !allWindowIds.includes(statusWindowId)
              ) {
                const xRandom = Math.floor(
                  Math.random() * (windowSize.width - 300)
                );
                const yRandom = Math.floor(
                  Math.random() * (windowSize.height - 34 - 426)
                );
                dispatch(
                  addWindow({
                    width: 400,
                    left: windowSize.width < 768 ? 0 : xRandom,
                    top: windowSize.width < 768 ? 0 : yRandom,
                    titleText:
                      Math.floor((wallet.adaAmount / 220000) * 100) +
                      "% of pre-sale.exe Completed ",
                    id: getNextWindowId(),
                    content: <StatusWindow windowId={getCurrentWindowId()} />,
                    visible: true,
                    closable: true,
                    active: true,
                  })
                );
                setStatusWindowId(getCurrentWindowId());
              } else {
                dispatch(toggleVisibility(statusWindowId));
              }
            }}
          >
            Check Status
          </button> */}
          <button
            // <iframe src="/default.asp"></iframe>

            onClick={() =>
              // window.open(
              //   "https://app.minswap.org/swap?currencySymbolA=&tokenNameA=&currencySymbolB=2adf188218a66847024664f4f63939577627a56c090f679fe366c5ee&tokenNameB=535441424c45",
              //   "_blank"
              // )
              dispatch(setNewActiveWindow(2))
            }
          >
            Buy
          </button>
          <button
            onClick={() => {
              dispatch(
                addWindow({
                  width: 820,
                  centered: true,
                  titleText: "TapTools",
                  maximizable: true,
                  content: (
                    <iframe
                      title="Taptools"
                      style={{
                        height: "100%",
                        width: "100%",
                        minHeight: "600px",
                        margin: "auto",
                      }}
                      src="https://www.taptools.io/charts/token?pairID=0be55d262b29f564998ff81efe21bdc0022621c12f15af08d0f2ddb1.f903a9e6e085799e438b9499355664a3e04dc6c42d528ea1573a9304cb155456&currency=ADA"
                    ></iframe>
                  ),
                  id: getNextWindowId(),
                  visible: true,
                  closable: true,
                  active: true,
                  maximized: false,
                })
              );
            }}
            // onClick={() =>
            //   window.open(
            //     "https://www.taptools.io/charts/token?pairID=0be55d262b29f564998ff81efe21bdc0022621c12f15af08d0f2ddb1.f903a9e6e085799e438b9499355664a3e04dc6c42d528ea1573a9304cb155456&currency=ADA",
            //     "_blank"
            //   )
            // }
          >
            Show Charts
          </button>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <div style={{ marginTop: "32px" }}>Copyright @ 2023 Stablecoin</div>
          <div style={{ textAlign: "center", fontSize: "10px" }}>
            Policy ID:{" "}
            <span style={{}}>
              2adf188218a66847024664f4f63939577627a56c090f679fe366c5ee
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const initialState: WindowProps[] = [
  {
    width: 340,
    centered: true,
    titleText: "$STABLE",
    content: <StableWindowContent />,
    id: getNextWindowId(),
    visible: true,
    closable: false,
    active: true,
    maximized: false,
  },
  {
    width: 470,

    centered: true,
    titleText: "Muesliswap",
    content: (
      <iframe
        src="https://embedded.muesliswap.com/swap?base=.&quote=2adf188218a66847024664f4f63939577627a56c090f679fe366c5ee.535441424c45&e_theme=auto&e_height=670px&e_width=450px"
        title=".-2adf188218a66847024664f4f63939577627a56c090f679fe366c5ee.535441424c45"
        id="muesliswap_integrated_swap_container"
        height="600px"
        width="100%"
      ></iframe>
    ),
    id: getNextWindowId(),
    visible: false,
    closable: false,
    active: false,
    maximized: false,
  },
];

export const windowsSlice = createSlice({
  name: "windows",
  initialState: initialState,
  reducers: {
    addWindow(state: WindowProps[], action: PayloadAction<WindowProps>) {
      if (action.payload.active) {
        state.forEach((window) => {
          window.active = false;
        });
      }
      state.push(action.payload);
      return state;
    },
    removeWindow(state: WindowProps[], action: PayloadAction<number>) {
      const allIds = state.map((window) => window.id);
      const index = allIds.indexOf(action.payload);

      state.splice(index, 1);
      return state;
    },
    maximizeWindow(state: WindowProps[], action: PayloadAction<number>) {
      state.forEach((window) => {
        if (window.id === action.payload) {
          window.maximized = !window.maximized;
        }
      });
      return state;
    },
    toggleVisibility(state: WindowProps[], action: PayloadAction<number>) {
      state.forEach((window) => {
        if (window.id === action.payload) {
          window.visible = !window.visible;
          if (window.visible) {
            state.forEach((window) => {
              if (window.id === action.payload) {
                window.active = true;
              } else {
                window.active = false;
              }
            });
          } else {
            window.active = false;
          }
        }
      });
      // state[action.payload.id].visible = !state[action.payload.id].visible;
      return state;
    },
    setNewActiveWindow(state: WindowProps[], action: PayloadAction<number>) {
      state.forEach((window) => {
        if (window.id === action.payload) {
          window.visible = true;
          window.active = true;
        } else {
          window.active = false;
        }
      });
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addWindow,
  removeWindow,
  toggleVisibility,
  setNewActiveWindow,
  maximizeWindow,
} = windowsSlice.actions;

export const getWindows = (state: RootState) => state.windows;

export default windowsSlice.reducer;
