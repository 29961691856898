import { configureStore } from '@reduxjs/toolkit';

import windowsReducer from "./slices/windowsReducer"
import walletReducer from "./slices/walletReducer"
import whaleWalletReducer from "./slices/whaleWalletsReducer"

export const store = configureStore({
  reducer: {
    windows: windowsReducer,
    wallet: walletReducer,
    whaleWallets: whaleWalletReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;