import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
// import windowsFont from "./fonts/WindowsRegular.ttf";
// import windowsCss from "./fonts/windows.css";
const windowsFont = require("./fonts/WindowsRegular.ttf");
const windowsCss = require("./fonts/windows.css");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
