import React from "react";
import ReactDOM from "react-dom";
import Draggable from "react-draggable";
import {
  WindowProps,
  maximizeWindow,
  removeWindow,
  setNewActiveWindow,
  toggleVisibility,
} from "../redux/slices/windowsReducer";
import { useDispatch } from "react-redux";

export const Window = (props: WindowProps) => {
  const dispatch = useDispatch();
  return (
    <Draggable
      cancel={[".title-bar-controls", ".window-body"] as any}
      onMouseDown={() => dispatch(setNewActiveWindow(props.id))}
    >
      <div
        className={
          "window handle " +
          (props.active ? "activeWindow" : "") +
          (props.maximized ? " maximizedWindow" : "")
        }
        style={{
          minHeight: "fit-content",
          width: props.maximized ? "" : `${props.width}px`,
          height: props.maximized ? `100%` : "fit-content",
          maxWidth: "100%",
          left:
            props.centered && !props.maximized
              ? 0
              : props.maximized
              ? "1px"
              : props.left,
          right: props.centered ? 0 : props.right,
          margin: props.centered && !props.maximized ? "auto" : 0,
          top: props.centered ? 0 : props.maximized ? 0 : props.top,
          bottom: props.centered ? 0 : props.bottom,
          display: props.visible ? "block" : "none",
          position: "absolute",
          zIndex: props.active ? 99999 : 0,
        }}
      >
        <div className="title-bar" style={{ background: "#00017C" }}>
          <div className="title-bar-text">{props.titleText}</div>
          <div className="title-bar-controls">
            <button
              aria-label="Minimize"
              onClick={() => dispatch(toggleVisibility(props.id))}
            ></button>
            {props.maximizable ? (
              <button
                aria-label="Maximize"
                onClick={() => {
                  dispatch(maximizeWindow(props.id));
                }}
              ></button>
            ) : (
              <></>
            )}

            <button
              aria-label="Close"
              style={{ cursor: "pointer" }}
              onClick={() => {
                // console.log("Clicked!");
                if (props.closable) {
                  dispatch(removeWindow(props.id));
                } else {
                  dispatch(toggleVisibility(props.id));
                }
              }}
            ></button>
          </div>
        </div>
        {/* <div className="title-bar">
        <div className="title-bar-text">{props.titleText}</div>
      </div> */}

        <div
          className="window-body"
          style={{
            height: props.maximized ? "calc(100vh - 70px)" : "",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          {props.content}
        </div>
      </div>
    </Draggable>
  );
};
