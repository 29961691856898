import { useEffect, useRef, useState } from "react";
import folder from "../images/folder.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addWindow,
  getCurrentWindowId,
  getNextWindowId,
  getWindows,
  setNewActiveWindow,
  toggleVisibility,
} from "../redux/slices/windowsReducer";
import { saveAs } from "file-saver";
import meme from "../images/memes/0.jpeg";
import meme1 from "../images/memes/1.png";
import meme2 from "../images/memes/2.jpeg";
import meme3 from "../images/memes/3.png";
import meme4 from "../images/memes/4.jpeg";
import meme5 from "../images/memes/5.jpeg";
import meme6 from "../images/memes/6.jpeg";
import meme7 from "../images/memes/7.jpeg";
import meme8 from "../images/memes/8.png";
import meme9 from "../images/memes/9.png";
import meme10 from "../images/memes/10.jpeg";
import meme11 from "../images/memes/11.jpeg";
import meme12 from "../images/memes/12.png";
import meme13 from "../images/memes/13.jpeg";
import meme14 from "../images/memes/14.png";
import meme15 from "../images/memes/15.png";
import meme16 from "../images/memes/16.jpeg";
import meme17 from "../images/memes/17.jpeg";
import meme18 from "../images/memes/18.jpeg";
import meme19 from "../images/memes/19.jpeg";
import meme20 from "../images/memes/20.jpeg";
import meme21 from "../images/memes/21.jpeg";
import meme22 from "../images/memes/22.png";
import meme23 from "../images/memes/23.jpeg";
import meme24 from "../images/memes/24.jpeg";
import meme25 from "../images/memes/25.jpeg";
import meme26 from "../images/memes/26.jpeg";
import meme27 from "../images/memes/27.jpeg";

import error from "../images/error.png";
import { sleep } from "../utils/generealUtils";

const memes = [
  meme,
  meme1,
  meme2,
  meme3,
  meme4,
  meme5,
  meme6,
  meme7,
  meme8,
  meme9,
  meme10,
  meme11,
  meme12,
  meme13,
  meme14,
  meme15,
  meme16,
  meme17,
  meme18,
  meme19,
  meme20,
  meme21,
  meme22,
  meme23,
  meme24,
  meme25,
  meme26,
  meme27,
];

export interface TaskbarProps {
  tasks: string[];
  onTrippleClick: () => void;
}

export const Taskbar = (props: TaskbarProps) => {
  const windows = useSelector(getWindows);
  const windowsRef = useRef(windows);
  windowsRef.current = windows;
  const dispatch = useDispatch();
  const [startedMemePopups, setStartedMemePopups] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (!startedMemePopups) {
      setStartedMemePopups(true);
      startRandomPopups();
    }
  }, []);

  const startRandomPopups = async () => {
    while (windowSize.width >= 1024) {
      await sleep(30000);
      if (windowsRef.current.length < 5) {
        const random = Math.floor(Math.random() * memes.length);
        const xRandom = Math.floor(Math.random() * (windowSize.width - 300));
        const yRandom = Math.floor(
          Math.random() * (windowSize.height - 34 - 426)
        );

        dispatch(
          addWindow({
            left: xRandom,
            top: yRandom,
            width: 300,
            titleText: "$STABLE Meme",
            content: (
              <img
                style={{ maxWidth: "100%" }}
                src={memes[random]}
                alt="meme_image"
              ></img>
            ),
            id: getNextWindowId(),
            visible: true,
            closable: true,
            active: false,
            maximized: false,
          })
        );
      }
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [memeClicks, setMemeClicks] = useState(0);
  const createMemeWindow = (event: any) => {
    const random = Math.floor(Math.random() * memes.length);

    const xRandom = Math.floor(Math.random() * (windowSize.width - 300));
    const yRandom = Math.floor(Math.random() * (windowSize.height - 34 - 426));
    setMemeClicks(memeClicks + 1);
    if (event.detail === 3 || memeClicks >= 2) {
      props.onTrippleClick();
      setMemeClicks(0);
    }
    if (windows.length > 40) {
      //play blue screen
      return;
    }
    if (windows.length > 15) {
      dispatch(
        addWindow({
          left: xRandom,
          top: yRandom,
          width: 375,
          titleText: "Critical Error",
          content: (
            <div
              style={{
                display: "flex",
                gap: "8px",
                padding: "8px",
                justifyContent: "space-between",
              }}
            >
              <img
                style={{ width: "32px", height: "32px" }}
                src={error}
                alt="error_image"
              />
              <div style={{ width: "180px" }}>
                The user has performed an illegal operation! The website will
                shut down.
                <br /> <br /> If the problem persists, contact the website
                owner.
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "inbetween",
                  gap: "8px",
                  marginLeft: "32px",
                }}
              >
                <button>Close</button>
                <div style={{ display: "flex", flexGrow: 1 }}></div>
                <button>Details</button>
              </div>
            </div>
          ),
          id: getNextWindowId(),
          visible: true,
          closable: true,
          active: true,
          maximized: false,
        })
      );
      return;
    }

    interface WizardContent {
      windowId: number;
    }
    const WizardContent = (props: WizardContent) => {
      const [activeMeme, setActiveMeme] = useState(random);
      // const [windowId, setWindowId] = useState(getCurrentWindowId());

      const handleNavigation = (nextIndex: number) => {
        if (nextIndex < 0) {
          setActiveMeme(memes.length - 1);
        }
        if (nextIndex >= memes.length) {
          setActiveMeme(0);
        }

        if (nextIndex >= 0 && nextIndex < memes.length) {
          setActiveMeme(nextIndex);
        }
      };

      function handleDownloadClick() {
        saveAs(memes[activeMeme], "stable_logo-" + activeMeme + ".jpg");
      }

      return (
        <>
          <div style={{ height: "280px" }}>
            <img
              style={{
                maxWidth: "280px",
                maxHeight: "280px",
                border: "1px solid black",
                margin: "auto",
                display: "block",
              }}
              alt="meme_image"
              src={memes[activeMeme]}
            ></img>
          </div>

          <div
            style={{
              width: "100%",
              height: "0px",
              margin: "16px 0",
              border: "1px solid",
              borderTopColor: "#878A8F",
              borderRightColor: "#878A8F",
              borderLeftColor: "#878A8F",
              borderBottomColor: "#fff",
            }}
          ></div>
          <div style={{ display: "flex", justifyContent: "end", gap: "8px" }}>
            <button onClick={() => handleNavigation(activeMeme - 1)}>
              Back
            </button>
            <button onClick={() => handleNavigation(activeMeme + 1)}>
              Next
            </button>
            <button onClick={() => handleDownloadClick()}>Download</button>
          </div>
        </>
      );
    };

    dispatch(
      addWindow({
        left: xRandom,
        top: yRandom,
        width: 300,
        titleText: "$STABLE Meme Wizard",
        id: getNextWindowId(),
        content: <WizardContent windowId={getCurrentWindowId()} />,
        visible: true,
        closable: true,
        active: true,
        maximized: false,
      })
    );
  };

  return (
    <div
      style={{
        background: "#C0C0C0",
        display: "flex",

        position: "fixed",
        bottom: 0,
        width: "100%",
        padding: "2px",
        borderTop: "2px solid white",
        zIndex: 999999,
      }}
    >
      <button
        style={{
          display: "flex",
          alignItems: "center",
          position: "sticky",
          paddingLeft: 0,
          marginRight: "8px",
        }}
        onClick={(e) => createMemeWindow(e)}
      >
        <img src={folder} alt="test" style={{ width: "30px" }} />
        Memes
      </button>
      <div
        className={"taskbar"}
        style={{ overflow: "scroll", display: "flex", gap: "8px" }}
      >
        {windows.map((window, index) => {
          return (
            <button
              key={"task" + index}
              className={window.active ? "active" : ""}
              style={{
                minWidth: "fit-content",

                boxShadow: window.active
                  ? "inset -1px -1px #fff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px grey"
                  : "",
              }}
              onClick={() => {
                if (window.active) {
                  dispatch(toggleVisibility(window.id));
                } else {
                  dispatch(setNewActiveWindow(window.id));
                }
              }}
            >
              {window.titleText}
            </button>
          );
        })}
      </div>
    </div>
  );
};
