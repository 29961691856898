import "./styles.css";
import { useEffect, useRef, useState } from "react";

export interface IDesktopIcon {
  text: string;
  image: string;
  onClick: () => void;
  whiteImage?: boolean;
}

export const DesktopIcon = (props: IDesktopIcon) => {
  const activeIconRef = useRef<HTMLDivElement>(null);
  const [activeDesktopIcon, setActiveDesktopIcon] = useState(false);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (
        activeIconRef.current &&
        !activeIconRef.current.contains(event.target)
      ) {
        setActiveDesktopIcon(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeIconRef]);

  const handleClick = (event: any) => {
    if (event.detail === 2) {
      if (window) {
        // window?.open(props.link, "_blank")?.focus();
        props.onClick();
      }
    }
  };

  return (
    <div onClick={() => setActiveDesktopIcon(true)} ref={activeIconRef}>
      <div
        className={"desktop-icon-container"}
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          gap: "4px",
          textAlign: "center",
          width: "fit-content",
          minWidth: "100px",
        }}
        onClick={handleClick}
      >
        <img
          src={props.image}
          alt="test"
          className={"desktop-icon"}
          style={{
            margin: "auto",
            filter: activeDesktopIcon
              ? "sepia(100%) hue-rotate(196deg) saturate(900%)"
              : "none",
          }}
        />
        <div
          style={{
            color: "#fff",
            fontSize: "16px",
            background: activeDesktopIcon ? "#00017C" : "transparent",
            width: "80px",
            margin: "auto",
          }}
        >
          {props.text}
        </div>
      </div>
    </div>
  );
};
