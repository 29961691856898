/**
 * Helper function to wait n amount of time
 * @param milliseconds: number - amount of time to wait
 */
export const sleep = (milliseconds: number) => {
    // console.log("WAITING " + milliseconds / 1000 + " SECONDS");
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };


  export const convertToAscii = (str: string) => {
    let asciiResult = '';
    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i);
      asciiResult += charCode.toString(16);
    }
    return asciiResult.trim();
  };

  export function extractTextBetween(
    source: string,
    start: string,
    end: string
  ): string[] {
    const pattern = new RegExp(`${start}(.*?)${end}`, "g");
    const matches = source.match(pattern) || [];
    const extractedText = matches.map((match) =>
      match.replace(start, "").replace(end, "")
    );
    return extractedText;
  }
  