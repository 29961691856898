import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { WindowProps } from "./windowsReducer";

interface WhaleWallet {
  adaAmount: number;
}
const initialState: WhaleWallet[] = [];

export const whaleWalletSlice = createSlice({
  name: "whaleWallets",
  initialState: initialState,
  reducers: {
    setWallets(state: WhaleWallet[], action: PayloadAction<WhaleWallet[]>) {
      state = action.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setWallets } = whaleWalletSlice.actions;

export const getWhaleWallets = (state: RootState) => state.whaleWallets;

export default whaleWalletSlice.reducer;
