import { DesktopIcon } from "../components/DesktopIcon";
import { Taskbar } from "../components/Taskbar";
import { Window } from "../components/Window";
import { saveAs } from "file-saver";
import minesweeper from "../images/minesweeper.png";
import minswap from "../images/icon-min-website.png";
import finance from "../images/directory_dial_up_networking-3.png";
import pinball from "../images/pinball-icon.png";
import pc from "../images/pc.png";
import discord from "../images/discord-logo.png";
import puzzleIcon from "../images/puzzleIcon.png";
import toolkit from "../images/toolkit-logo.png";

import logo from "../images/toolkit/stable-logo-small.png";
import logo1 from "../images/toolkit/stable-logo-white.png";
import logo2 from "../images/toolkit/stable-logo-whiteskin.png";
import logo3 from "../images/toolkit/stable-logo-greyskin.png";

import error from "../images/error.png";

import { useDispatch, useSelector } from "react-redux";
import {
  addWindow,
  getCurrentWindowId,
  getNextWindowId,
  getWindows,
  removeWindow,
  setNewActiveWindow,
} from "../redux/slices/windowsReducer";
import { useEffect, useState } from "react";
import { sleep } from "../utils/generealUtils";

export const DesktopPage = () => {
  const windows = useSelector(getWindows);

  const dispatch = useDispatch();
  const [blueScreenVisible, setBlueScreenVisible] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (windows.length > 25) {
      setBlueScreenVisible(true);
      windows.forEach((window) => {
        if (window.closable) {
          dispatch(removeWindow(window.id));
        }
      });
      const wait = async (time: number) => {
        await sleep(time);
        setBlueScreenVisible(false);
      };
      wait(5000);
    }
  }, [windows]);

  function handleDownloadClick(index: number) {
    let image = logo;
    switch (index) {
      case 0:
        image = logo;
        break;
      case 1:
        image = logo1;
        break;
      case 2:
        image = logo2;
        break;
      case 3:
        image = logo3;
        break;
    }
    saveAs(image, "stable_logo-" + index + ".jpg");
  }

  const handlePinballLoad = (event: any) => {
    event.target.muted = true;
  };

  return (
    <div
      style={{
        background: "#377E80",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "fit-content",
          height: "calc(100% - 34px)",
          flexWrap: "wrap",
        }}
      >
        <DesktopIcon
          text={"Twitter"}
          image={pc}
          onClick={() =>
            window?.open("https://twitter.com/stablecoinada", "_blank")?.focus()
          }
        />

        <DesktopIcon
          text={"Discord"}
          image={discord}
          whiteImage={true}
          onClick={() =>
            window?.open("https://discord.gg/3V8CZzA66Z", "_blank")?.focus()
          }
        />
        <div style={{ height: "105px" }} />
        <DesktopIcon
          text={"Meme Toolkit"}
          image={toolkit}
          onClick={() =>
            dispatch(
              addWindow({
                width: 600,
                centered: true,
                titleText: "Meme toolkit",
                content: (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={logo}
                        alt="test"
                        style={{ width: "25%", maxWidth: "150px" }}
                      />
                      <img
                        src={logo1}
                        alt="test"
                        style={{ width: "25%", maxWidth: "150px" }}
                      />
                      <img
                        src={logo2}
                        alt="test"
                        style={{ width: "25%", maxWidth: "150px" }}
                      />
                      <img
                        src={logo3}
                        alt="test"
                        style={{ width: "25%", maxWidth: "150px" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ width: "150px", textAlign: "center" }}>
                        No background
                      </div>
                      <div style={{ width: "150px", textAlign: "center" }}>
                        No background
                      </div>
                      <div style={{ width: "150px", textAlign: "center" }}>
                        White background
                      </div>
                      <div style={{ width: "150px", textAlign: "center" }}>
                        Grey background
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "32px",
                        marginBottom: "16px",
                        position: "relative",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "90%",
                          height: "0px",
                          left: 0,
                          right: 0,
                          margin: "auto",
                          border: "1px solid",
                          borderTopColor: "#878A8F",
                          borderRightColor: "#878A8F",
                          borderLeftColor: "#878A8F",
                          borderBottomColor: "#fff",
                          position: "absolute",
                          top: "-16px",
                        }}
                      ></div>
                      <div
                        style={{
                          width: "150px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button onClick={() => handleDownloadClick(0)}>
                          Download
                        </button>
                      </div>
                      <div
                        style={{
                          width: "150px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button onClick={() => handleDownloadClick(1)}>
                          Download
                        </button>
                      </div>
                      <div
                        style={{
                          width: "150px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button onClick={() => handleDownloadClick(2)}>
                          Download
                        </button>
                      </div>
                      <div
                        style={{
                          width: "150px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button onClick={() => handleDownloadClick(3)}>
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                ),
                id: getNextWindowId(),
                visible: true,
                closable: true,
                active: true,
                maximized: false,
              })
            )
          }
        />
        <DesktopIcon
          text={"Minesweeper"}
          image={minesweeper}
          onClick={() =>
            dispatch(
              addWindow({
                width: 350,
                centered: true,
                titleText: "Minesweeper",
                content: (
                  <iframe
                    title="Minesweeper"
                    style={{ height: "350px" }}
                    src="./programs/minesweeper/index.html"
                  ></iframe>
                ),
                id: getNextWindowId(),
                visible: true,
                closable: true,
                active: true,
                maximized: false,
              })
            )
          }
        />
        <DesktopIcon
          text={"Pinball"}
          image={pinball}
          onClick={() =>
            dispatch(
              addWindow({
                width: 630,
                centered: true,
                maximizable: true,
                titleText: "Pinball",
                id: getNextWindowId(),
                content: (
                  <iframe
                    title="Pinball"
                    style={{ height: "100%", minHeight: "450px" }}
                    src="./programs/pinball/space-cadet.html"
                    onLoad={handlePinballLoad}
                  ></iframe>
                ),
                visible: true,
                closable: true,
                active: true,
                maximized: false,
              })
            )
          }
        />

        <div style={{ height: "105px" }} />

        <DesktopIcon
          text={"Buy with most Crypto"}
          image={finance}
          onClick={() =>
            dispatch(
              addWindow({
                width: 400,

                centered: true,
                maximizable: true,
                titleText: "Uex.Finance",
                id: getNextWindowId(),
                content: (
                  <iframe
                    title="uex.finance"
                    style={{ height: "100%", minHeight: "450px" }}
                    src="https://uex.finance/"
                  ></iframe>
                ),
                visible: true,
                closable: true,
                active: true,
                maximized: false,
              })
            )
          }
        />
        <DesktopIcon
          text={"Minswap"}
          image={minswap}
          onClick={() =>
            window.open(
              "https://app.minswap.org/swap?currencySymbolA=&tokenNameA=&currencySymbolB=2adf188218a66847024664f4f63939577627a56c090f679fe366c5ee&tokenNameB=535441424c45",
              "_blank"
            )
          }
        />
        <div style={{ height: "85px" }} />
        <DesktopIcon
          text={"FB8"}
          image={puzzleIcon}
          onClick={() => {
            dispatch(
              addWindow({
                width: 400,
                centered: true,
                maximizable: true,
                titleText: "FB8",
                id: getNextWindowId(),
                content: (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      paddingTop: "8px",
                    }}
                  >
                    <div>
                      1) Something you need to enter an event or travel on
                      public transport. A golden one might lead to a chocolate
                      factory.
                    </div>
                    <div>
                      2) Taste or aroma. Vanilla, chocolate, and strawberry are
                      popular ones.
                    </div>
                    <div>
                      3) A large body of saltwater that covers most of the
                      Earth's surface.
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "0px",

                        border: "1px solid",
                        borderTopColor: "#878A8F",
                        borderRightColor: "#878A8F",
                        borderLeftColor: "#878A8F",
                        borderBottomColor: "#fff",
                        margin: "8px auto",
                        boxSizing: "border-box",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      <button disabled onClick={() => {}}>
                        Back
                      </button>
                      <button>Next</button>
                      <button>Cancel</button>
                    </div>
                  </div>
                ),
                visible: true,
                closable: true,
                active: true,
                maximized: false,
              })
            );
          }}
        />
      </div>

      {windows.map((window, index) => {
        return (
          <Window
            maximized={window.maximized}
            key={"window" + window.id + "-" + index}
            width={window.width}
            titleText={window.titleText}
            id={window.id}
            content={window.content}
            centered={window.centered}
            visible={window.visible}
            left={window.left}
            right={window.right}
            top={window.top}
            bottom={window.bottom}
            closable={window.closable}
            active={window.active}
            maximizable={window.maximizable}
          />
        );
      })}

      <BlueScreen visible={blueScreenVisible} />

      <Taskbar
        tasks={[]}
        onTrippleClick={async () => {
          windows.forEach((window) => {
            if (window.closable) {
              dispatch(removeWindow(window.id));
            }
          });
          setBlueScreenVisible(true);
          await sleep(5000);
          setBlueScreenVisible(false);
        }}
      />
    </div>
  );
};

const BlueScreen = (props: { visible: boolean }) => {
  return props.visible ? (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        background: "#00017C",
        display: "flex",
        flexDirection: "column",
        zIndex: 99999999999,
        color: "#fff",
        gap: "16px",
        fontSize: "22px",
      }}
    >
      <div style={{ color: "#fff" }}>
        A Problem has been detected and windows has been shut down to prevent
        damage to your computer.
      </div>
      <div>
        The problem seems to be caused by the following file: STABLE.EXE
      </div>
      <div>PAGE_FAULT_IN_NONPAGED_AREA</div>
      <div>
        If this is the first time you've seen this stop error screen, restart
        your computer. If this screen appears again, follow these steps:
      </div>
      <div>
        Check to make sure any new hardware or software is properly installed.
        If this is a new installation, ask your hardware or software
        manufacturer for any windows updates you might need.
      </div>
      <div>
        If problems continue, disable or remvoe any newly installed hardware or
        software. Disable BIOS memory options such as caching or shadowing. If
        yo uneed to use Safe Mode to remove or disable components, restart your
        computer, press F8 to select Advanced Startup Options, and then select
        Safe Mode.
      </div>

      <div>Technical Information:</div>
      <div>
        *** STOP: 0x00000050 (0xFD3094C2, 0x0000001, 0xFBFE7617, 0x000000
      </div>
      <div>
        *** STABLE.EXE - Address FBFE7617 base at FBFE5000, DateStamp 3d6dd67c
      </div>
    </div>
  ) : (
    <></>
  );
};
